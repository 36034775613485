import React, { useState, useEffect } from "react";
import { TextField } from "react-admin";
import { httpClient } from "../../data";

import { ADMIN_API_URL } from "../../constants";

interface IProps {
  record?: {
    id?: string;
  };
}

const SSNField = ({ record = {}, ...rest }: IProps) => {
  const [ssn, setSSN] = useState("");

  const fetchSSN = (e) => {
    e.preventDefault();
    if (!record.id) {
      return;
    }

    setSSN("Loading...");
    return httpClient(`${ADMIN_API_URL}/users/${record.id}/ssn`, {
      method: "GET",
    })
      .then(({ json }) => {
        setSSN(json["ssn"]);
      })
      .catch(() => {
        setSSN("");
      });
  };

  // Clear SSN when record changes
  useEffect(() => {
    setSSN("");
  }, [record]);

  if (ssn && ssn.length > 0) {
    return <TextField {...rest} source="ssn" record={{ ssn }} />;
  } else {
    return (
      <TextField
        {...rest}
        source="ssn"
        record={{ ssn: "Click to view" }}
        onClick={fetchSSN}
      />
    );
  }
};

SSNField.defaultProps = TextField.defaultProps;

export default SSNField;
