import React, { useState, useEffect } from "react";
import UserListSearchForm from "./components/UserListSearchForm";
import UserListItem from "./UserListItem";

const UserList = (props) => {
  const data = props.location?.state?.data;

  if (!data) {
    props.history.push(`/usersList`);
  }

  return (
    <div>
      <UserListSearchForm />
      <div style={{ paddingTop: "20px" }}>
        {data && data.length
          ? data.map((user: any) => (
              <UserListItem user={user} history={props.history} />
            ))
          : null}
      </div>
    </div>
  );
};

export default UserList;
