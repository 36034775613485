import React from "react";
import { TextField } from "react-admin";

const formatMoney = (val = 0, currency = "EUR") => {
  const cents = val % 100;
  return `${Math.floor(val / 100)}.${
    cents < 10 ? `0${cents}` : cents
  } ${currency}`;
};

const MoneyField = ({ source, record = {}, ...rest }) => (
  <TextField
    {...rest}
    source={`${source}_as_money`}
    record={{
      ...record,
      [`${source}_as_money`]: formatMoney(record[source], record["currency"])
    }}
  />
);

export default MoneyField;
