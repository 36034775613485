import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import { createStyles, withStyles } from "@material-ui/core/styles";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

const serviceNames = (serviceBreaks) =>
  serviceBreaks.map((s) => s.service).join(", ");
const maintenanceBeginsAt = (serviceBreaks) =>
  serviceBreaks.reduce(
    (earliest, s) =>
      s.beginsAt && s.beginsAt < earliest ? s.beginsAt : earliest,
    new Date()
  );
const maintenanceEndsAt = (serviceBreaks) =>
  serviceBreaks.reduce(
    (latest, s) => (s.endsAt && s.endsAt > latest ? s.endsAt : latest),
    new Date()
  );
const maintenanceDuration = (serviceBreaks) => {
  const beginsAt = maintenanceBeginsAt(serviceBreaks);
  const endsAt = maintenanceEndsAt(serviceBreaks);

  const beginDate = formatDate(beginsAt);
  const endDate = formatDate(endsAt);

  return `from ${beginDate !== endDate ? `${beginDate} ` : " "}${formatTime(
    beginsAt
  )} to ${beginDate !== endDate ? `${endDate} ` : " "}${formatTime(endsAt)}`;
};

const formatDate = (date: Date) =>
  `${date.getDay()}.${date.getMonth() + 1}.${date.getFullYear()}`;

const formatTime = (date: Date) =>
  `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;

const InfoSection = ({ serviceBreaks, classes, className = null }) => (
  <div className={classNames(className, classes.infoSection)}>
    <NotificationsActiveIcon
      className={classNames(classes.infoItem, classes.icon)}
    />
    <p className={classes.infoItem}>{`Service${
      serviceBreaks.length > 1 ? "s" : ""
    }: ${serviceNames(serviceBreaks)} ${
      serviceBreaks.length > 1 ? "are" : "is"
    } under maintenance ${maintenanceDuration(serviceBreaks)}`}</p>
  </div>
);

const shake = `shake-service-break-icon`;

const styles = () =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "row",
      flex: "auto",
    },
    infoSection: {
      display: "flex",
      flex: "auto",
    },
    infoItem: {
      flex: "initial",
      margin: "1rem 0.2rem",
    },
    icon: {
      transformOrigin: "top center",
      animation: `1s ${shake} linear 10`,
    },
    [`@keyframes ${shake}`]: {
      "0%": {
        color: "inherit",
        rotate: "0deg",
      },
      "25%": {
        rotate: "10deg",
        color: "orange",
      },
      "50%": {
        color: "inherit",
      },
      "75%": {
        rotate: "-10deg",
        color: "orange",
      },
      "100%": {
        rotate: "0deg",
        color: "inherit",
      },
    },
  });

const ServiceBreaksInfo = ({ classes, className, serviceBreaks }) => (
  <div className={classNames(className, classes.wrapper)}>
    {serviceBreaks.length > 0 ? (
      <InfoSection {...{ serviceBreaks, classes }} />
    ) : undefined}
  </div>
);

const mapStateToProps = ({ serviceBreaks }) => ({
  serviceBreaks: serviceBreaks || [],
});

export default connect(mapStateToProps)(withStyles(styles)(ServiceBreaksInfo));
