import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

const AdminUserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="password" />
    </SimpleForm>
  </Edit>
);

export default AdminUserEdit;
