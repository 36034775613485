import { IMFAEvent } from "../domain";

export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  const {page, perPage} = (params && params.pagination) || {}
  const searchParams = new URLSearchParams({page: (page - 1).toString(), perPage})
  return {
    url: `${apiUrl}/users/${params.filter.userPhone}/mfas?${searchParams}`,
    options: { method: "GET" }
  };
};

export const convertHTTPResponse = (
  response,
  type,
  resource,
  params
): { data: IMFAEvent[]; total: number } => {
  const { data, meta } = response.json;

  return {
    data: data.map((item, index) => ({id: index, ...item})),
    total: meta.total
  };
};

export default { convertDataRequestToHTTP, convertHTTPResponse };
