import React from "react";
import { Layout, AppBar } from "react-admin";

import {Info} from "./serviceBreaks"

const CustomAppBar = (props) => {
    return <AppBar {...props}>
        <Info />
    </AppBar>
};

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;

export default CustomLayout;
