import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
} from "react-admin";

const UserCardsList = ({ userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userCards"
      filter={{ userPhone }}
      title=" / Cards"
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="card_number" />
        <TextField source="name" />
        <TextField source="expiry" />
        <BooleanField source="payment_capable" label="Payment capable" />
        <BooleanField source="cvc_required" label="Is CVC required" />
        <TextField source="master_product" />
        <TextField source="product" />
        <DateField
          source="updated_at"
          showTime
          locales="fi-FI"
          options={{ dateStyle: "short", timeStyle: "short" }}
        />
        <DateField
          source="last_used_at"
          showTime
          locales="fi-FI"
          options={{ dateStyle: "short", timeStyle: "short" }}
        />
        <TextField source="iban" />
      </Datagrid>
    </List>
  ) : null;

export default UserCardsList;
