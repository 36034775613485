import React from "react";
import PropTypes from "prop-types";
import { DateField } from "react-admin";

const TimestampField = ({ source, record = {}, options = {}, ...rest }) => (
  <DateField
    {...rest}
    source={`${source}_as_date`}
    record={{ ...record, [`${source}_as_date`]: new Date(parseInt(record[source])) }}
    locales="en-GB"
  />
);

TimestampField.defaultProps = {...DateField.defaultProps, showTime: true}

TimestampField.propTypes = {
  ...DateField.propTypes,
  label: PropTypes.string, // Used internally by react-admin for Table header
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export default TimestampField;
