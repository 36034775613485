import React from "react";
import { List, Datagrid, TextField, ChipField, DateField } from "react-admin";

const UserLoginsList = ({ userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userLogins"
      filter={{ userPhone }}
      title=" / Logins"
      bulkActionButtons={false}
      sort={{field: "logged_in_at", order: "DESC"}}
    >
      <Datagrid>
        <TextField source="user_cognito_id" />
        <ChipField source="type" />
        <DateField source="logged_in_at" showTime />
      </Datagrid>
    </List>
  ) : null;

export default UserLoginsList;
