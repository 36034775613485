import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  ArrayField,
  SingleFieldList,
  EditButton,
  DeleteWithConfirmButton
} from "react-admin";

import TimestampField from "../components/TimestampField";

import { adminUserTypeToString, adminUserScopeToString } from "../authProvider";

interface IAdminUserFieldProps {
  record?: object;
  source: string;
}

const AdminUserTypeField = ({
  record,
  source,
  ...rest
}: IAdminUserFieldProps) => (
  <ChipField
    {...rest}
    source={`${source}_as_str`}
    record={{
      ...record,
      [`${source}_as_str`]: record
        ? adminUserTypeToString[record[source]]
        : null
    }}
  />
);

AdminUserTypeField.defaultProps = ChipField.defaultProps;

const AdminUserScopesField = ({
  record,
  source,
  ...rest
}: IAdminUserFieldProps) => (
  <ArrayField
    {...rest}
    source={`${source}_with_names`}
    record={{
      ...record,
      [`${source}_with_names`]: record
        ? record[source]
            .split(",")
            .map(s => ({ name: adminUserScopeToString[s], scope: s }))
        : []
    }}
  >
    <SingleFieldList>
      <ChipField source="name" />
    </SingleFieldList>
  </ArrayField>
);

AdminUserScopesField.defaultProps = ArrayField.defaultProps;

const AdminUserList = ({ hasEdit, ...rest }) => (
  <List {...{ hasEdit, ...rest }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <AdminUserTypeField source="user_type" />
      <AdminUserScopesField source="scopes" />
      <TimestampField source="created_at" />
      <TimestampField source="logged_in_at" />
      {hasEdit ? <EditButton /> : null}
      {hasEdit ? <DeleteWithConfirmButton /> : null}
    </Datagrid>
  </List>
);

export default AdminUserList;
