import restClient from "./restClient";

export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  const { userPhone, ...filter } = params.filter;
  return restClient.convertDataRequestToHTTP(
    `${apiUrl}/users/${userPhone}`,
    type,
    "accounts",
    { ...params, filter }
  );
};

export default { convertDataRequestToHTTP };
