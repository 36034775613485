import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

const UserMfasList = ({ userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userMfas"
      filter={{ userPhone }}
      title=" / MFAs"
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="user_id" sortable={false} />
        <TextField source="message" sortable={false} />
        <DateField source="created" showTime locales="en-GB" sortable={false} />
      </Datagrid>
    </List>
  ) : null;

export default UserMfasList;
