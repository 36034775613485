import React from "react";
import { Admin, Resource } from "react-admin";

import PeopleIcon from "@material-ui/icons/People";
import AdminIcon from "@material-ui/icons/PeopleOutline";

import "./App.css";

import CustomLayout from "./CustomLayout";
import authProvider, { hasPermissionFor } from "./authProvider";
import { Login } from "./login";
import { UserSearch, UserShow, UserList, UserListSearch } from "./users";
import { AdminUserList, AdminUserCreate, AdminUserEdit } from "./adminUsers";
import { ADMIN_API_URL } from "./constants";

import { serviceBreaksSaga, serviceBreaksReducer } from "./serviceBreaks";

import dataProvider from "./data";
import i18nProvider from "./i18n";

function App() {
  return (
    <Admin
      {...{ authProvider, i18nProvider }}
      appLayout={CustomLayout}
      dataProvider={dataProvider(ADMIN_API_URL)}
      customSagas={[serviceBreaksSaga]}
      customReducers={{ serviceBreaks: serviceBreaksReducer }}
      loginPage={Login}
    >
      {(permissions) => {
        const has = hasPermissionFor.bind(null, permissions);

        const userReadResources = has("users", "read")
          ? [
              <Resource name="userDevices" />,
              <Resource name="userMfas" />,
              <Resource name="userLogins" />,
              <Resource name="userServices" />,
              <Resource name="userReceivingIban" />,
              <Resource name="userCards" />,
              <Resource name="userAccounts" />,
            ]
          : [];

        const transactionReadResources = has("userTransactions", "read")
          ? [
              <Resource name="userPayments" />,
              <Resource name="userPaymentRequests" />,
            ]
          : [];

        const userWriteResources = has("users", "write")
          ? [<Resource name="blocked-users" />]
          : [];

        const adminReadResources = has("admins", "read")
          ? [
              <Resource
                name="admin"
                list={AdminUserList}
                create={has("admins", "write") ? AdminUserCreate : null}
                edit={has("admins", "write") ? AdminUserEdit : null}
                icon={AdminIcon}
              />,
            ]
          : [];

        return [
          <Resource
            name="users"
            icon={PeopleIcon}
            list={UserSearch}
            show={UserShow}
          />,
          <Resource
            name="usersList"
            icon={PeopleIcon}
            list={UserListSearch}
            show={UserList}
          />,
          ...userReadResources,
          ...transactionReadResources,
          ...userWriteResources,
          ...adminReadResources,
        ];
      }}
    </Admin>
  );
}

export default App;
