import React from "react";
import TextField from "@material-ui/core/TextField";
import { Field } from "redux-form";

const Input = ({
  meta: { touched, error } = { touched: false, error: "" }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const normalize = (value) => {
  console.log("Normalize called", value);
  // Don't change if length is less than 6
  if (value.length <= 6) {
    return value;
  }
  if (/^[\d\s]+$/.test(value)) {
    // Add local prefix to phone numbers and remove whitespace
    return value.replace(/^(\+358|0)/, "+358").replace(/\s/g, "");
  }
  // Remove whitespace
  return value.replace(/\s/g, "");
};

const PhoneOrSSNField = (props) => (
  <Field
    {...props}
    component={Input}
    normalize={props.normalize ? normalize : (value) => value}
    autoFocus
    autoComplete="off"
  />
);

export const validator = (
  value: string,
  { translate }: { translate: (string) => string }
): string => {
  if (!value) {
    return translate("ra.validation.required");
  }
  return "";
};

PhoneOrSSNField.defaultProps = {
  normalize: true,
};

export default PhoneOrSSNField;
