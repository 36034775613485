import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
} from "react-admin";

import { DomainObject } from "../domain";

export const convertDataRequestToHTTP = (apiUrl, type, resource, params) => {
  let url = "";
  const options: RequestInit = {};
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    case GET_MANY: {
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    case UPDATE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case CREATE:
      url = `${apiUrl}/${resource}`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "DELETE";
      break;
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

export const convertHTTPResponse = (
  response,
  type,
  resource,
  params
): DomainObject => {
  const { headers, json } = response;
  const data = json && json.data ? json.data : json;
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      return {
        data,
        total: headers.has("content-range")
          ? parseInt(headers.get("content-range").split("/").pop(), 10)
          : data.length,
      };
    case CREATE:
      return { data: { ...params.data, ...data } };
    default:
      return { data };
  }
};

export default { convertDataRequestToHTTP, convertHTTPResponse };
