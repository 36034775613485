import React from "react";
import classnames from "classnames";

import compose from "recompose/compose";

import { Title } from "react-admin";
import Card from "@material-ui/core/Card";
import { withStyles, createStyles } from "@material-ui/core/styles";

import UserSearchForm from "./components/UserSearchForm";

export const styles = createStyles({
  root: {
    display: "flex"
  },
  card: {
    position: "relative",
    flex: "1 1 auto",
    display: "flex"
  }
});

const UserSearch = ({
  hasCreate,
  hasShow,
  hasEdit,
  hasList,
  basePath,
  className,
  title,
  classes,
  handleSubmit,
  isLoading,
  translate,
  ...rest
}) => {
  const { defaultTitle } = rest;
  return (
    <div
      className={classnames("search-page", classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      <Title title={title} defaultTitle={defaultTitle} />
      <Card className={classes.card}>
          <UserSearchForm />
      </Card>
    </div>
  );
};

const sanitizeRestProps = ({ ...rest }) => rest;

const enhance = compose(
  withStyles(styles)
);

export default enhance(UserSearch);
