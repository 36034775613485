import React from "react"
import compose from "recompose/compose";
import {connect} from "react-redux";
import {crudDelete} from "react-admin";

import Button from "@material-ui/core/Button";
import BlockIcon from '@material-ui/icons/Block';

const handleClick = ({crudDelete, user}, e) => {
    e.preventDefault();
    crudDelete("blocked-users", user.block_id)
}

const UnblockUserButton = props => (<Button onClick={handleClick.bind(null, props)}><BlockIcon /> Unblock</Button>)

const enhance = compose(connect(() => {}, {crudDelete}));

export default enhance(UnblockUserButton);