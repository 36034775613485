import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateField
} from "react-admin";

const UserServicesList = ({ userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userServices"
      filter={{ userPhone }}
      title=" / Services"
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" />
        <BooleanField source="visible" />
        <DateField source="consent_given" showTime locales="en-GB" />
        <ScopesField source="scopes" />
      </Datagrid>
    </List>
  ) : null;

const ScopesField = ({ record = {}, source, ...rest }) => (
  <ArrayField
    {...rest}
    source={`${source}_with_names`}
    record={{
      ...record,
      [`${source}_with_names`]: record[source]
        ? record[source].map(s => ({ name: s, scope: s }))
        : []
    }}
  >
    <SingleFieldList linkType={false} >
      <ChipField source="name" />
    </SingleFieldList>
  </ArrayField>
);

export default UserServicesList;
