import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Confirm, showNotification } from "react-admin";
import Button from "@material-ui/core/Button";

import { httpClient } from "../../data";
import { ADMIN_API_URL } from "../../constants";

interface IProps {
  userPhone?: string;
  showNotification: (msg: string, level?: string) => void;
}

const ResetStudentStatusButton = ({
  userPhone,
  showNotification,
  ...rest
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const reset = () => {
    setIsOpen(false);
    return httpClient(`${ADMIN_API_URL}/users/${userPhone}/student-status`, {
      method: "DELETE"
    })
      .then(() => {
        showNotification("Student card reset is in processing");
      })
      .catch(err => {
        showNotification(err.message, "error");
      });
  };

  return (
    <Fragment>
      <Button color="primary" {...rest} onClick={() => setIsOpen(true)}>
        Reset student status
      </Button>
      <Confirm
        {...{ isOpen }}
        title="Reset student status"
        content="Are you sure?"
        onConfirm={reset}
        onClose={() => setIsOpen(false)}
      />
    </Fragment>
  );
};

export default connect(
  undefined,
  { showNotification }
)(ResetStudentStatusButton);
