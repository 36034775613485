import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
  EmailField,
  ChipField,
  ImageField,
  DeleteWithConfirmButton
} from "react-admin";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";

import { hasPermissionFor } from "../authProvider";

import {
  BlockUserButton,
  UnblockUserButton,
  TimestampField
} from "../components";

import {
  UserSearchForm,
  ResetGraphButton,
  ResetStudentStatusButton,
  SSNField
} from "./components";

import UserDevicesList from "./UserDevicesList";
import UserMfasList from "./UserMfasList";
import UserLoginsList from "./UserLoginsList";
import UserPaymentsList from "./UserPaymentsList";
import UserPaymentRequestsList from "./UserPaymentRequestsList";
import UserServicesList from "./UserServicesList";
import UserIbanShow from "./UserIbanShow";
import UserCardsList from "./UserCardsList"
import UserAccountsList from "./UserAccountsList"
import { IUser, TPermissions } from "../domain";

interface IActionsProps {
  basePath?: string;
  data?: IUser;
  permissions: TPermissions;
  resource: string;
}

const UserShowActions = ({
  basePath,
  data,
  permissions,
  resource,
  ...rest
}: IActionsProps) => {
  const has = hasPermissionFor.bind(null, permissions);

  return (
    <CardActions>
      <UserSearchForm />
      {has("users", "write") && data && data.student_card_id ? (
        <ResetStudentStatusButton userPhone={data.phone_number} />
      ) : (
        undefined
      )}
      {has("users", "write") && data ? (
        <ResetGraphButton userPhone={data.phone_number} />
      ) : (
        undefined
      )}
      {has("users", "delete") && data ? (
        <DeleteWithConfirmButton {...{ basePath, resource }} record={data} />
      ) : (
        undefined
      )}
      {has("users", "write") && data && !data.block_id ? (
        <BlockUserButton {...{ basePath, resource, ...rest }} user={data} />
      ) : (
        undefined
      )}
      {has("users", "write") && data && data.block_id ? (
        <UnblockUserButton {...{ basePath, resource, ...rest }} user={data} />
      ) : (
        undefined
      )}
    </CardActions>
  );
};

interface ITitleProps {
  record?: IUser;
}

const UserTitle = ({ record }: ITitleProps) => (
  <span>
    User:{" "}
    {record && record.full_name
      ? record.full_name
          .split(" ")
          .map(
            name =>
              `${name.slice(0, 1).toUpperCase()}${name.slice(1).toLowerCase()}`
          )
          .join(" ")
      : undefined}
  </span>
);

const styles = theme =>
  createStyles({
    basicContent: {
      columns: "2 auto",
      display: "block"
    }
  });

export const UserShow = ({
  id,
  permissions,
  record,
  resource,
  classes,
  ...rest
}) => {
  const has = hasPermissionFor.bind(null, permissions);
  return (
    <Show
      {...rest}
      {...{ id, record, resource }}
      actions={<UserShowActions {...{ permissions, resource }} />}
      title={<UserTitle />}
    >
      <TabbedShowLayout>
        <Tab label="Basic" contentClassName={classes.basicContent}>
          <ImageField source="avatar_thumb_url" />
          <TextField source="phone_number" />
          <ChipField source="state" />
          <SSNField source="ssn" />
          <TextField source="address_street" />
          <TextField source="address_postal_code" />
          <TextField source="address_postal_office" />
          <TextField source="address_country" />
          <BooleanField source="address_verified" />
          <EmailField source="email" />
          <BooleanField source="email_verified" />
          <TimestampField source="birth_date" showTime={false} />
          <TextField source="full_name" />
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="nick_name" />
          <TextField source="locale" />
          <TextField source="address_origin" />
          <TextField source="op_owner_banks" />
          <TextField source="identity_source" />
          <BooleanField source="student" />
          <TextField source="student_status" />
          <TextField source="student_academy" />
          <ImageField source="student_profile_image_url" />
          <ChipField source="block_status" />
          <TextField source="block_reason" />
          <BooleanField source="pep" />
          <TimestampField source="pep_date" />
          <TextField source="client_platform" />
          <TextField source="client_version" />
          <TimestampField source="last_login_at" />
          <TimestampField source="confirmed_at" />
          <TimestampField source="created_at" />
          <TimestampField source="updated_at" />
        </Tab>
        <Tab label="Devices" path="devices">
          <UserDevicesList {...rest} userPhone={id} />
        </Tab>
        <Tab label="MFAs" path="mfas">
          <UserMfasList {...rest} userPhone={id} />
        </Tab>
        <Tab label="Logins" path="logins">
          <UserLoginsList {...rest} userPhone={id} />
        </Tab>
        <Tab label="Receiving IBAN" path="iban">
          <UserIbanShow {...rest} userPhone={id} />
        </Tab>
        <Tab label="Cards" path="cards">
          <UserCardsList {...rest} userPhone={id} />
        </Tab>
        <Tab label="OP Bank Accounts" path="accounts">
          <UserAccountsList {...rest} userPhone={id} />
        </Tab>
        {has("userTransactions", "read") ? (
          <Tab label="Payments" path="payments">
            <UserPaymentsList {...rest} userPhone={id} />
          </Tab>
        ) : (
          undefined
        )}
        {has("userTransactions", "read") ? (
          <Tab label="Requests" path="requests">
            <UserPaymentRequestsList {...rest} userPhone={id} />
          </Tab>
        ) : (
          undefined
        )}
        <Tab label="Services" path="services">
          <UserServicesList {...rest} userPhone={id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default withStyles(styles)(UserShow);
