export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  return {
    url: `${apiUrl}/users/${params.id}/receiving-iban`,
    options: { method: "GET" }
  };
};

export const convertHTTPResponse = (
  response,
  type,
  resource,
  params
): { data } => ({ data: { ...response.json, id: params.id } });

export default { convertDataRequestToHTTP, convertHTTPResponse };
