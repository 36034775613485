import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

const UserDevicesList = ({ userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userDevices"
      filter={{ userPhone }}
      title=" / Devices"
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="ip" />
        <TextField source="sdk" />
        <DateField source="last_auth" showTime locales="en-GB" />
      </Datagrid>
    </List>
  ) : null;

export default UserDevicesList;
