import { ILoginEvent } from "../domain";

const fieldMappings = {
  logged_in_at: "logged_in",
  type: "log_type",
};

export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  const { page, perPage } = params?.pagination || {};
  const { field: orderBy, order: orderType } = params?.sort || {};

  const searchParams = new URLSearchParams({
    page: (page - 1).toString(),
    perPage,
  });
  if (orderBy) {
    searchParams.append(
      "sort",
      JSON.stringify([fieldMappings[orderBy] || orderBy, orderType || "DESC"])
    );
  }
  return {
    url: `${apiUrl}/users/${params.filter.userPhone}/logins?${searchParams}`,
    options: { method: "GET" },
  };
};

export const convertHTTPResponse = (
  response,
  type,
  resource,
  params
): { data: ILoginEvent[]; total: number } => {
  const { data, meta } = response.json;

  return {
    data: data.map((item) => ({ id: item.logged_in_at, ...item })),
    total: parseInt(meta.total),
  };
};

export default { convertDataRequestToHTTP, convertHTTPResponse };
