import { IUser } from "../domain";

export const convertHTTPResponse = (
  response,
  type,
  resource,
  params
): { data: IUser } => {
  const { json } = response;

  return { data: { ...json, id: json.phone_number } };
};

export default { convertHTTPResponse };
