import { DELETE } from "react-admin";

import restClient from "./restClient";

export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  if (type === DELETE) {
    return {
      url: `${apiUrl}/users/${params.id}/graph`,
      options: { method: "DELETE" }
    };
  } else {
    return restClient.convertDataRequestToHTTP(
      apiUrl,
      type,
      "userGraph",
      params
    );
  }
};

export default { convertDataRequestToHTTP };
