import React, { useState } from "react";

const UserListItem = ({ user, history }) => {
  const [showSSN, toggleSSN] = useState(false);

  const handleSSNClick = (e) => {
    e.stopPropagation();
    toggleSSN(!showSSN);
  };

  return (
    <div
      onClick={() => history.push(`/users/${user.phone_number}/show`)}
      key={user.id}
      style={{
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        paddingBottom: "20px",
      }}
    >
      <div style={{ flex: 1 }}>
        <label
          style={{
            display: "flex",
            color: "rgba(0,0,0,0.54)",
            fontSize: "1rem",
          }}
        >
          Full Name
        </label>
        <div style={{ display: "flex", padding: "8px 0 4px" }}>
          <span>{user.full_name}</span>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <label
          style={{
            display: "flex",
            color: "rgba(0,0,0,0.54)",
            fontSize: "1rem",
          }}
        >
          Phone number
        </label>
        <div style={{ display: "flex", padding: "8px 0 4px" }}>
          <span>{user.phone_number}</span>
        </div>
      </div>
      <div
        style={{ cursor: "pointer", flex: 1 }}
        onClick={(e) => handleSSNClick(e)}
      >
        <label
          style={{
            display: "flex",
            color: "rgba(0,0,0,0.54)",
            fontSize: "1rem",
          }}
        >
          Ssn
        </label>
        <div style={{ display: "flex", padding: "8px 0 4px" }}>
          <span>{showSSN ? user.ssn : "Click to show"}</span>
        </div>
      </div>
    </div>
  );
};

export default UserListItem;
