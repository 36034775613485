import React from "react";
import { showNotification } from "react-admin";
import { push } from "react-router-redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { withStyles, createStyles } from "@material-ui/core/styles";
import { withTranslate, withDataProvider, GET_MANY } from "ra-core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";

import PhoneOrSSNField from "./PhoneOrSSNField";
import { validator as phoneOrSSNValidator } from "./PhoneOrSSNField";

export const styles = createStyles({
  root: {
    display: "flex",
  },
  card: {
    position: "relative",
    flex: "1 1 auto",
    display: "flex",
  },
  form: {
    flex: "1 1 auto",
    display: "flex",
  },
  search: {
    width: "100%",
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  noResults: { padding: 20 },
});

const searchUser = ({ name }, dispatch, { dataProvider, onSuccess }) =>
  dataProvider(GET_MANY, "users/list", { id: name })
    .then(({ data }) => {
      console.log("data", data);
      if (typeof onSuccess === "function") {
        return onSuccess(data);
      } else {
        console.log("go to usersList");
        dispatch(
          push({
            pathname: `/usersList/${data[0].full_name}/show`,
            state: { data },
          })
        );
      }
    })
    .catch((err) => {
      dispatch(push(`/usersList`));
      dispatch(showNotification("User not found", "error"));
    });

const UserListSearchForm = ({
  handleSubmit,
  classes,
  isLoading,
  translate,
}) => (
  <form onSubmit={handleSubmit(searchUser)} className={classes.form}>
    <div className={classes.form}>
      <div className={classes.search}>
        <PhoneOrSSNField
          id="name"
          name="name"
          label={"Name"}
          disabled={isLoading}
          normalize={false}
        />
      </div>
    </div>
    <Button
      variant="raised"
      type="submit"
      color="primary"
      disabled={isLoading}
      className={classes.button}
    >
      {isLoading && (
        <CircularProgress className={classes.icon} size={18} thickness={2} />
      )}
      <SearchIcon />
    </Button>
  </form>
);

const mapStateToProps = (state) => ({
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  withDataProvider,
  withStyles(styles),
  withTranslate,
  connect(mapStateToProps),
  reduxForm({
    form: "userListSearch",
    validate: (values, props) => {
      const errors = { phoneorssn: "" };
      errors.phoneorssn = phoneOrSSNValidator(values.phoneorssn, props);

      return errors;
    },
  })
);

export default enhance(UserListSearchForm);
