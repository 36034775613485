import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGOUT,
  AUTH_ERROR
} from "react-admin";
import { ADMIN_API_URL } from "./constants";

import { httpClient } from "./data";
import { TPermissions, TResource, TResourceAction } from "./domain";

export const adminUserType = {
  ASPA_T1: 1,
  ASPA_T2: 2,
  ASPA_T3: 3,
  PARTNER: 10,
  ADMIN: 90,
  SUPER_ADMIN: 99
};

export const adminUserTypeToString = {
  [adminUserType.ASPA_T1]: "aspa_t1",
  [adminUserType.ASPA_T2]: "aspa_t2",
  [adminUserType.ASPA_T3]: "aspa_t3",
  [adminUserType.PARTNER]: "partner",
  [adminUserType.ADMIN]: "admin",
  [adminUserType.SUPER_ADMIN]: "super_admin"
};

export const adminUserScope = {
  USER_READ: 1,
  USER_WRITE: 2,
  USER_TX_READ: 3,
  USER_TX_WRITE: 4,
  USER_DELETE: 5,
  P2B_READ: 10,
  P2B_WRITE: 11,
  ADMIN_USER_READ: 90,
  ADMIN_USER_WRITE: 91
};

export const adminUserScopeToString = {
  [adminUserScope.USER_READ]: "user_read",
  [adminUserScope.USER_WRITE]: "user_write",
  [adminUserScope.USER_TX_READ]: "user_tx_read",
  [adminUserScope.USER_TX_WRITE]: "user_tx_write",
  [adminUserScope.USER_DELETE]: "user_delete",
  [adminUserScope.P2B_READ]: "user_p2b_read",
  [adminUserScope.P2B_WRITE]: "user_p2b_write",
  [adminUserScope.ADMIN_USER_READ]: "admin_user_write",
  [adminUserScope.ADMIN_USER_WRITE]: "admin_user_read"
};

export default (type, params) => {
  switch (type) {
    case AUTH_CHECK:
      return parseInt(localStorage.getItem("sessionExpiresAt") || "0") >
        Date.now()
        ? Promise.resolve()
        : Promise.reject();
    case AUTH_LOGIN:
      const {
        user_type: userType,
        scopes: userScopes,
        session_expires_at: sessionExpiresAt
      } = params;
      localStorage.setItem("userType", userType);
      localStorage.setItem("userScopes", userScopes);
      localStorage.setItem("sessionExpiresAt", sessionExpiresAt);
      return params;
    case AUTH_LOGOUT:
      destroySession();
      return Promise.resolve();
    case AUTH_ERROR:
      const { status } = params;
      if (status === 401 || status === 403 || !status) {
        destroySession();
        return Promise.reject();
      }
      return Promise.resolve();
    case AUTH_GET_PERMISSIONS:
      const scopes = (localStorage.getItem("userScopes") || "").split(",");
      return Promise.resolve({
        userType: parseInt(localStorage.getItem("userType") || "0"),
        resources: {
          users: {
            delete: scopes.some(s => parseInt(s) === adminUserScope.USER_DELETE),
            read: scopes.some(s => parseInt(s) === adminUserScope.USER_READ),
            write: scopes.some(s => parseInt(s) === adminUserScope.USER_WRITE)
          },
          admins: {
            read: scopes.some(
              s => parseInt(s) === adminUserScope.ADMIN_USER_READ
            ),
            write: scopes.some(
              s => parseInt(s) === adminUserScope.ADMIN_USER_WRITE
            )
          },
          userTransactions: {
            read: scopes.some(s => parseInt(s) === adminUserScope.USER_TX_READ),
            write: scopes.some(
              s => parseInt(s) === adminUserScope.USER_TX_WRITE
            )
          }
        }
      });
    default:
  }
};

const destroySession = () => {
  localStorage.removeItem("userType");
  localStorage.removeItem("userScopes");
  localStorage.removeItem("sessionExpiresAt");
};

export const login = ({ username, password }) => {
  return httpClient(`${ADMIN_API_URL}/login`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ username, password })
  });
};

export const submitMfa = ({ otp, ts, nonce }) => {
  return httpClient(`${ADMIN_API_URL}/login/otp`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ otp, ts, nonce })
  });
};

export const resetPassword = ({ password, ts, nonce }) => {
  return httpClient(`${ADMIN_API_URL}/login/password-reset`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ password, ts, nonce })
  });
};

export const setupMfaDevice = ({ otp, ts, nonce }) => {
  return httpClient(`${ADMIN_API_URL}/login/otp-device`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ otp, ts, nonce })
  });
};

export const hasPermissionFor = (
  permissions: TPermissions,
  resource: TResource,
  action: TResourceAction
): boolean =>
  permissions &&
  permissions.resources &&
  permissions.resources[resource] &&
  permissions.resources[resource][action];
