import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
} from "react-admin";

const UserAccountsList = ({ userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userAccounts"
      filter={{ userPhone }}
      title=" / OP Bank Accounts"
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="iban" />
        <TextField source="name" />
        <BooleanField source="payment_capable" label="Payment capable" />
        <TextField source="product_name" label="OP Account product name" />
        <DateField
          source="updated_at"
          showTime
          locales="fi-FI"
          options={{ dateStyle: "short", timeStyle: "short" }}
        />
        <DateField
          source="last_used_at"
          showTime
          locales="fi-FI"
          options={{ dateStyle: "short", timeStyle: "short" }}
        />
      </Datagrid>
    </List>
  ) : null;

export default UserAccountsList;
