import englishMessages from "ra-language-english";

import englishDomainMessages from "./en";

const messages = {
  en: { ...englishMessages, ...englishDomainMessages }
};
const i18nProvider = locale => messages[locale];

export default i18nProvider;
