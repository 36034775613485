import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  BooleanField,
  Filter,
  TextInput,
  FunctionField,
  ImageField,
  SimpleShowLayout
} from "react-admin";
import { createStyles, withStyles } from "@material-ui/core/styles";

import {
  MoneyField,
  PaymentStateField,
  TimestampField,
  PaymentMethodField,
  PaymentOptionField
} from "../components";

const styles = theme => ({
  targetOrSource: {
    overflow: "hidden",
    "text-overflow": "ellipsis",
    "max-width": "12em",
    "white-space": "nowrap"
  },
  targetOrSourceContent: {
    overflow: "hidden",
    "text-overflow": "ellipsis"
  }
});

const UserPaymentsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" />
  </Filter>
);

const LoyaltyField = ({ kind, ...props }) => {
  if (kind === "studentcard") {
    return (
      <SimpleShowLayout {...props}>
        <TextField source="type" />
        <TextField source="association" />
        <BooleanField source="enrolled" />
      </SimpleShowLayout>
    );
  }
  return null;
};

const DeliveredProfileInformationField = props => {
  const source = props.source || "receipt_data";

  const data = (props.record && props.record[source]) || {};

  const memberships = Object.keys(data)
    .map(k => data[k])
    .map(({ meta, payload }) => {
      return (
        <div key={meta.loyalty_program_name}>
          <SimpleShowLayout {...props} record={meta}>
            <TextField label="Loyalty program" source="loyalty_program_name" />
            <ImageField source="loyalty_program_logo_url" addLabel={false} />
          </SimpleShowLayout>
          <LoyaltyField
            {...props}
            kind={meta.loyalty_program_name}
            record={payload}
          />
        </div>
      );
    });

  return (
    <div>
      <h3>{props.label}</h3>
      {memberships}
    </div>
  );
};

const additionalInfoStyles = theme =>
  createStyles({
    layout: {
      columns: "2 auto",
      display: "block"
    },
    section: {
      breakInside: "avoid"
    },
    targetOrSource: {
      overflow: "hidden",
      "text-overflow": "ellipsis",
      "max-width": "12em",
      "white-space": "nowrap"
    },
    targetOrSourceContent: {
      overflow: "hidden",
      "text-overflow": "ellipsis"
    }
  });

const AdditionalInfoSection = withStyles(additionalInfoStyles)(
  ({ classes, children, ...props }) => (
    <SimpleShowLayout {...props} className={classes.section}>
      {children}
    </SimpleShowLayout>
  )
);

const AdditionalInfoPanel = withStyles(additionalInfoStyles)(
  ({ classes, ...props }) => (
    <div className={classes.layout}>
      <AdditionalInfoSection {...props}>
        <h3>Summary</h3>
        <TextField {...props} source="account_tx_archive_id" />
        <TextField source="message" />
        <TextField source="optional_message" />
        <TextField source="reference" />
        <MoneyField addLabel source="amount" />
        <PaymentMethodField addLabel source="payment_method" />
        <PaymentOptionField addLabel source="payment_option" />
        <PaymentStateField addLabel source="state" />
      </AdditionalInfoSection>
      <AdditionalInfoSection {...props}>
        <h3>Payer details</h3>
        <FunctionField
          label="Name"
          source="from_full_name"
          render={record =>
            toTitleCase(record.from_full_name || record.from_company_name)
          }
        />
        <TextField
          source="from_source"
          className={classes.targetOrSourceContent}
          cellClassName={classes.targetOrSource}
        />
        <TextField source="from_user_id" />
        <TextField label="Business id" source="from_company_business_id" />
      </AdditionalInfoSection>
      <AdditionalInfoSection {...props}>
        <h3>Beneficiary details</h3>
        <FunctionField
          label="Name"
          source="to_full_name"
          render={record =>
            toTitleCase(record.to_full_name || record.to_company_name)
          }
        />
        <TextField
          source="to_target"
          className={classes.targetOrSourceContent}
          cellClassName={classes.targetOrSource}
        />
        <TextField source="to_user_id" />
        <TextField label="Business id" source="to_company_business_id" />
        <TextField label="To POS" source="to_pos_id" />
        <TextField label="To Service" source="to_service_id" />
        <DeliveredProfileInformationField label="Delivered profile information" />
      </AdditionalInfoSection>
      <AdditionalInfoSection {...props}>
        <h3>Technical information</h3>
        <TextField label="Payment API id" source="external_payment_id" />
      </AdditionalInfoSection>
    </div>
  )
);

const UserPaymentsList = ({ classes, userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userPayments"
      filter={{ userPhone }}
      title=" / Payments"
      filters={<UserPaymentsFilter />}
      bulkActionButtons={false}
      sort={{field: "created_at", order: "DESC"}}
    >
      <Datagrid expand={<AdditionalInfoPanel />}>
        <TimestampField source="created_at" />
        <ChipField source="type" />
        <PaymentMethodField source="payment_method" />
        <PaymentOptionField source="payment_option" />
        <PaymentStateField source="state" />
        <BooleanField source="instant_payment" label="Realtime" />
        <MoneyField source="amount" />
        <FunctionField
          label="To"
          source="to_full_name"
          render={record =>
            toTitleCase(record.to_full_name || record.to_company_name)
          }
        />
        <TextField
          source="to_target"
          className={classes.targetOrSourceContent}
          cellClassName={classes.targetOrSource}
        />
        <TextField source="to_phonenumber" />
        <FunctionField
          label="From"
          source="from_full_name"
          render={record =>
            toTitleCase(record.from_full_name || record.from_company_name)
          }
        />
        <TextField
          source="from_source"
          className={classes.targetOrSourceContent}
          cellClassName={classes.targetOrSource}
        />
        <TextField source="from_phonenumber" />
      </Datagrid>
    </List>
  ) : null;

const toTitleCase = s =>
  (s || "")
    .split(/([\s-])/g)
    .map(
      part => `${part.slice(0, 1).toUpperCase()}${part.slice(1).toLowerCase()}`
    )
    .join("");

export default withStyles(styles)(UserPaymentsList);
