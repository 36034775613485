
import {FETCH_ALL_SUCCESS} from "./actions"

const initialState = []

export default (state = initialState, action) => {
    if (action.type === FETCH_ALL_SUCCESS) {
        return action.payload.data;
    } else {
        return state
    }
}