import React from "react";
import { ChipField } from "react-admin";

const mapStateToLabel = (mappings = {}, value) => {
  if (!mappings[value]) {
    return value || "";
  }
  return mappings[value];
};

const StateField = ({ source, stateMappings = {}, record = {}, ...rest }) => (
  <ChipField
    {...rest}
    source={`${source}_as_text`}
    record={{
      ...record,
      [`${source}_as_text`]: mapStateToLabel(stateMappings, record[source])
    }}
  />
);

export default StateField;

const paymentOrderStates = {
  0: "Pending",
  10: "Paid",
  20: "Cancelled",
  30: "Confirming",
  40: "Card charged",
  50: "Expired",
  60: "Failed",
  70: "Pending refund",
  80: "Refund",
  90: "Paid, waiting confirmation",
  100: "Waiting refund"
};

export const PaymentStateField = props => (
  <StateField {...props} stateMappings={paymentOrderStates} />
);

const paymentRequestStates = {
  0: "Initialized",
  1: "Open",
  2: "Paid",
  3: "Declined",
  4: "Cancelled",
  5: "Expired",
  6: "Invalidated"
};

export const PaymentRequestStateField = props => (
  <StateField {...props} stateMappings={paymentRequestStates} />
);

const paymentOptions = {
  0: "Account",
  1: "Card",
  3: "Siirto",
  4: "Limiitti",
  5: "BLE",
  6: "Invoice",
  7: "P2P",
  8: "NFC",
  9: "QR"
};

export const PaymentOptionField = props => (
  <StateField {...props} stateMappings={paymentOptions} />
);

const paymentMethods = {
 0: "P2B",
 1: "P2P",
 2: "PSP",
 3: "P2B_WEB"
};

export const PaymentMethodField = props => (
  <StateField {...props} stateMappings={paymentMethods} />
);