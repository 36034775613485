import React from "react";
import { Show, SimpleShowLayout, TextField, BooleanField } from "react-admin";

import { TimestampField } from "../components";

interface IRestProps {
  resource?: string
  record?: object,
  [k: string]: any
}

const sanitizeRest = ({resource, record, ...rest}: IRestProps) => rest

export const UserIbanShow = ({ userPhone, ...rest }) => {
  return (
    <Show
      {...sanitizeRest(rest)}
      resource="userReceivingIban"
      id={userPhone}
      title=" / Receiving IBAN"
    >
      <SimpleShowLayout>
        <TextField source="p2p_iban" />
        <TimestampField source="p2p_updated_at" />
        <BooleanField source="siirto_enabled" />
        <TextField source="siirto_iban" />
        <TimestampField source="siirto_updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserIbanShow;
