export default {
    pivo: {
        auth: {
            otp_code: "MFA code"
        },
        users: {
            block: "Block",
            phone_or_ssn: "Phone number or SSN"
        }
    }
}