import { IDevice } from "../domain";

export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  return {
    url: `${apiUrl}/users/${params.filter.userPhone}/devices`,
    options: { method: "GET" }
  };
};

export const convertHTTPResponse = (
  response,
  type,
  resource,
  params
): { data: IDevice[]; total: number } => {
  const { json } = response;
  const devices = (json.data || []).reduce(
    (all, d) =>
      all.concat(
        (d.devices || []).map(device => ({ ...device, id: device.key }))
      ),
    []
  );

  const { sort } = params;

  return {
    data: devices.sort((a, b) => {
      if (sort.order === "ASC") {
        return a[sort.field].localeCompare(b[sort.field]);
      } else {
        return b[sort.field].localeCompare(a[sort.field]);
      }
    }),
    total: devices.length
  };
};

export default { convertDataRequestToHTTP, convertHTTPResponse };
