
class ServiceBreak {
    id: string;
    service: string;
    beginsAt: Date | null;
    endsAt: Date | null;

    constructor(payload) {
        const {service, begins_at, ends_at} = payload;
        
        this.id = this.service = service;
        this.beginsAt = begins_at ? new Date(begins_at) : null;
        this.endsAt = ends_at ? new Date(ends_at) : null;
    }
}

export const convertHTTPResponse = (response, type, resource, params) => {
  const { json } = response;

  const data =
    json && json.data
      ? (json.data || []).map((item) => (new ServiceBreak(item)))
      : [];

  return {
    data,
    total: data.length,
  };
};

export default { convertHTTPResponse };
