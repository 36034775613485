import React from "react";
import { showNotification } from "react-admin";
import { push } from "react-router-redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { withStyles, createStyles } from "@material-ui/core/styles";
import { withTranslate, withDataProvider, GET_ONE } from "ra-core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";

import PhoneOrSSNField from "./PhoneOrSSNField";
import { validator as phoneOrSSNValidator } from "./PhoneOrSSNField";

export const styles = createStyles({
  root: {
    display: "flex"
  },
  card: {
    position: "relative",
    flex: "1 1 auto",
    display: "flex"
  },
  form: {
    flex: "1 1 auto",
    display: "flex"
  },
  search: {
    width: "100%"
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap"
  },
  noResults: { padding: 20 }
});

const searchUser = ({ phoneorssn }, dispatch, { dataProvider, onSuccess }) =>
  dataProvider(GET_ONE, "users", { id: phoneorssn })
    .then(({ data }) => {
      if (typeof onSuccess === "function") {
        return onSuccess(data);
      } else {
        dispatch(push(`/users/${data.phone_number}/show`));
      }
    })
    .catch(err => {
      dispatch(push(`/users`));
      dispatch(showNotification("User not found", "error"));
    });

const UserSearchForm = ({ handleSubmit, classes, isLoading, translate }) => (
  <form onSubmit={handleSubmit(searchUser)} className={classes.form}>
    <div className={classes.form}>
      <div className={classes.search}>
        <PhoneOrSSNField
          id="phoneorssn"
          name="phoneorssn"
          label={translate("pivo.users.phone_or_ssn")}
          disabled={isLoading}
        />
      </div>
    </div>
    <Button
      variant="raised"
      type="submit"
      color="primary"
      disabled={isLoading}
      className={classes.button}
    >
      {isLoading && (
        <CircularProgress className={classes.icon} size={18} thickness={2} />
      )}
      <SearchIcon />
    </Button>
  </form>
);

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0
});

const enhance = compose(
  withDataProvider,
  withStyles(styles),
  withTranslate,
  connect(mapStateToProps),
  reduxForm({
    form: "userSearch",
    validate: (values, props) => {
      const errors = { phoneorssn: "" };
      errors.phoneorssn = phoneOrSSNValidator(values.phoneorssn, props);

      return errors;
    }
  })
);

export default enhance(UserSearchForm);
