import { fetchUtils, UPDATE_MANY, DELETE_MANY } from "react-admin";
import { DomainObject } from "../domain";

import restClient from "./restClient";

import users from "./users";
import usersList from "./usersList";
import userDevices from "./userDevices";
import userMfas from "./userMfas";
import userLogins from "./userLogins";
import userPayments from "./userPayments";
import userPaymentRequests from "./userPaymentRequests";
import userServices from "./userServices";
import userGraph from "./userGraph";
import userIban from "./userIban";
import userCards from "./userCards";
import userAccounts from "./userAccounts";

import { serviceBreaksProvider } from "../serviceBreaks";

export interface IResponse extends ResponseInit {
  json: object;
}

type THttpClient = (u: String, o?: RequestInit) => Promise<IResponse>;

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient: THttpClient = defaultClient) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    if (resource === "userDevices") {
      return userDevices.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userMfas") {
      return userMfas.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userLogins") {
      return userLogins.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userPayments") {
      return userPayments.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userPaymentRequests") {
      return userPaymentRequests.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userServices") {
      return userServices.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userGraph") {
      return userGraph.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userReceivingIban") {
      return userIban.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userCards") {
      return userCards.convertDataRequestToHTTP(apiUrl, type, params);
    } else if (resource === "userAccounts") {
      return userAccounts.convertDataRequestToHTTP(apiUrl, type, params);
    } else {
      return restClient.convertDataRequestToHTTP(
        apiUrl,
        type,
        resource,
        params
      );
    }
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (
    response: IResponse,
    type,
    resource,
    params
  ): DomainObject => {
    if (resource === "users" && type === "GET_MANY") {
      return usersList.convertHTTPResponse(response, type, resource, params);
    } else if (resource === "users") {
      return users.convertHTTPResponse(response, type, resource, params);
    } else if (resource === "userDevices") {
      return userDevices.convertHTTPResponse(response, type, resource, params);
    } else if (resource === "userMfas") {
      return userMfas.convertHTTPResponse(response, type, resource, params);
    } else if (resource === "userLogins") {
      return userLogins.convertHTTPResponse(response, type, resource, params);
    } else if (resource === "userServices") {
      return userServices.convertHTTPResponse(response, type, resource, params);
    } else if (resource === "userReceivingIban") {
      return userIban.convertHTTPResponse(response, type, resource, params);
    } else if (resource === "service-breaks") {
      return serviceBreaksProvider.convertHTTPResponse(
        response,
        type,
        resource,
        params
      );
    } else {
      return restClient.convertHTTPResponse(response, type, resource, params);
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all<IResponse>(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all<IResponse>(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then((response) =>
      convertHTTPResponse(response, type, resource, params)
    );
  };
};

const defaultClient = (
  url: String,
  options: RequestInit = {}
): Promise<IResponse> => {
  const headers = new Headers(options.headers);
  headers.set("Accept", "application/json");

  const csrfToken = localStorage.getItem("adminCsrfToken");
  if (csrfToken) {
    headers.set("X-CSRF-Token", csrfToken);
  }

  options.credentials = "include";
  options.headers = headers;

  return fetchUtils.fetchJson(url, options).then((response) => {
    const headers = new Headers(response.headers);
    const csrfToken = headers.get("X-CSRF-Token");
    if (csrfToken) {
      localStorage.setItem("adminCsrfToken", csrfToken);
    }
    return response;
  });
};

export const httpClient = defaultClient;
