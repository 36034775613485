export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  return {
    url: `${apiUrl}/users/${params.filter.userPhone}/services`,
    options: { method: "GET" }
  };
};

export const convertHTTPResponse = (
  response,
  type,
  resource,
  params
): { data, total } => {
  const { json } = response;

  const { sort } = params;

  return {
    data: json.sort((a, b) => {
      if (sort.order === "ASC") {
        return a[sort.field].localeCompare(b[sort.field]);
      } else {
        return b[sort.field].localeCompare(a[sort.field]);
      }
    }),
    total: json.length
  };
};

export default { convertDataRequestToHTTP, convertHTTPResponse };
