import React from "react";
import faker from "faker";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  DisabledInput
} from "react-admin";

import { adminUserType, adminUserTypeToString } from "../authProvider";
import { UserSearchForm } from "../users";
import { IAdminPermissions } from "../domain";

const availableUserTypes = permissions => {
  const { userType: currentUserType } = permissions || { userType: null };

  if (!currentUserType) {
    return [];
  }
  return Object.values(adminUserType)
    .filter(t => t <= currentUserType)
    .map(t => ({
      id: t,
      name: adminUserTypeToString[t]
    }));
};

interface IAdminUserCreateProps {
  permissions?: IAdminPermissions;
  initializeCreate: (object) => void;
  location: {
    state?: {
      record?: {
        phone?: string;
      };
    };
  };
}

const AdminUserCreate = ({
  permissions,
  initializeCreate,
  location,
  ...rest
}: IAdminUserCreateProps) => {
  const record = (location && location.state && location.state.record) || {};
  return (
    <div>
      <UserSearchForm onSuccess={initializeCreate} />
      <Create {...{ permissions, location, ...rest }}>
        <SimpleForm defaultValue={record} redirect="list">
          <DisabledInput source="phone" />
          <DisabledInput source="name" />
          <SelectInput
            source="user_type"
            choices={availableUserTypes(permissions)}
            disabled={!record.phone}
          />
          <TextInput source="password" disabled={!record.phone} />
        </SimpleForm>
      </Create>
    </div>
  );
};

export default connect(
  null,
  (dispatch, { basePath }) => ({
    initializeCreate: record =>
      dispatch(
        replace({
          pathname: `${basePath}/create`,
          state: {
            record: {
              phone: record.phone_number,
              name: record.full_name,
              user_type: 1,
              password: faker.commerce
                .productName()
                .split(" ")
                .map(
                  n => n.slice(0, 1).toUpperCase() + n.slice(1).toLowerCase()
                )
                .join("")
            }
          }
        })
      )
  })
)(AdminUserCreate);
