import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  Filter,
  TextInput
} from "react-admin";

import {
  MoneyField,
  PaymentRequestStateField,
  TimestampField
} from "../components";

const UserPaymentRequestsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" />
  </Filter>
);

const UserPaymentRequestsList = ({ userPhone, ...rest }) =>
  userPhone ? (
    <List
      {...rest}
      resource="userPaymentRequests"
      filter={{ userPhone }}
      title=" / Payment requests"
      filters={<UserPaymentRequestsFilter />}
      bulkActionButtons={false}
      sort={{field: "created_at", order: "DESC"}}
    >
      <Datagrid>
        <TimestampField source="created_at" />
        <ChipField source="type" />
        <PaymentRequestStateField source="state" />
        <MoneyField source="amount" />
        <TextField source="to_phonenumber" />
        <TextField source="from_source" />
        <TextField source="from_phonenumber" />
      </Datagrid>
    </List>
  ) : null;

export default UserPaymentRequestsList;
