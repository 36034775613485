import { GET_LIST } from "react-admin";

export const FETCH_ALL = "service-breaks/FETCH_ALL";
export const FETCH_ALL_SUCCESS = `${FETCH_ALL}_SUCCESS`;
export const FETCH_ALL_FAILURE = `${FETCH_ALL}_FAILURE`;
export const fetchAll = () => ({
  type: FETCH_ALL,
  payload: {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "service", order: "ASC" },
  },
  meta: {
    fetch: GET_LIST,
    resource: "service-breaks",
  },
});
