import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { userLogin, Notification } from "react-admin";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/Lock";
import {
  MuiThemeProvider,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";

import { SubmissionError } from "redux-form";
import LoginForm from "./LoginForm";
import MfaForm from "./MfaForm";
import ChangePasswordForm from "./ChangePasswordForm";
import SetupMfaForm from "./SetupMfaForm";
import {
  login,
  submitMfa,
  resetPassword,
  setupMfaDevice,
} from "../authProvider";
import { MuiThemeProviderProps } from "@material-ui/core/styles/MuiThemeProvider";

const styles = (theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  });

const steps = {
  LOGIN: "login",
  MFA: "mfa",
  CHANGE_PASSWORD: "change_password",
  SETUP_MFA: "setup_mfa",
};

interface ILoginProps extends MuiThemeProviderProps {
  userLogin: (payload: object) => void;
  className?: string;
  classes: {
    main: string;
    card: string;
    avatar: string;
    icon: string;
  };
  history?: object;
  match?: object;
  location?: object;
}

interface ILoginState {
  step: string;
  nonce?: string;
  ts?: number;
  message?: string;
  qrCode?: string;
}

class Login extends Component<ILoginProps, ILoginState> {
  constructor(props) {
    super(props);
    this.state = {
      step: steps.LOGIN,
      nonce: undefined,
      ts: undefined,
      message: undefined,
    };
  }

  handleLoginChallenge = (json) => {
    const { userLogin } = this.props;

    if (json.message) {
      throw new SubmissionError({_error: json.message})
    }
    if (json.challenge) {
      return this.setState({
        step: json.challenge,
        nonce: json.nonce,
        ts: json.ts,
        qrCode: json.qr_code ? json.qr_code : undefined,
      });
    } else {
      return userLogin(json);
    }
  };

  loginHandler = (formValues, dispatch, props) => {
    return login(formValues)
      .then(({ json }) => this.handleLoginChallenge(json))
      .catch((err) => {
        if (err.message === "Unauthorized") {
          throw new SubmissionError({ _error: "Invalid username or password" });
        }
        throw new SubmissionError({
          _error: "Something went wrong, please try again...",
        });
      });
  };

  mfaHandler = (formValues, dispatch, props) => {
    const { nonce, ts } = this.state;
    return submitMfa(Object.assign({}, formValues, { nonce, ts })).then(({ json }) =>
      this.handleLoginChallenge(json)
    );
  };

  resetPasswordHandler = (formValues, dispatch, props) => {
    const { nonce, ts } = this.state;
    return resetPassword(
      Object.assign({}, formValues, { nonce, ts })
    ).then(({ json }) => this.handleLoginChallenge(json));
  };

  mfaSetupHandler = (formValues, dispatch, props) => {
    const { nonce, ts } = this.state;
    return setupMfaDevice(
      Object.assign({}, formValues, { nonce, ts })
    ).then(({ json }) => this.handleLoginChallenge(json));
  };

  render() {
    const { classes, className, ...rest } = this.props;

    const { step, qrCode } = this.state;
    return (
      <MuiThemeProvider theme={this.props.theme}>
        <div
          className={classNames(className, classes.main)}
          {...sanitizeRestProps(rest)}
        >
          <Card className={this.props.classes.card}>
            <div className={this.props.classes.avatar}>
              <Avatar className={this.props.classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            {step === steps.LOGIN ? (
              <LoginForm onSubmit={this.loginHandler} />
            ) : undefined}
            {step === steps.MFA ? (
              <MfaForm onSubmit={this.mfaHandler} />
            ) : undefined}
            {step === steps.CHANGE_PASSWORD ? (
              <ChangePasswordForm onSubmit={this.resetPasswordHandler} />
            ) : undefined}
            {step === steps.SETUP_MFA ? (
              <SetupMfaForm onSubmit={this.mfaSetupHandler} qrCode={qrCode} />
            ) : undefined}
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

const sanitizeRestProps = ({
  history,
  match,
  location,
  ...rest
}: {
  history?: any;
  match?: any;
  location?: any;
}) => rest;

export default connect(undefined, { userLogin })(
  withStyles(styles)(Login)
);
