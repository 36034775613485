import React, { Component } from "react";
import { connect } from "react-redux";
import { showNotification } from "react-admin";
import Button from "@material-ui/core/Button";

import {httpClient} from "../../data"
import { ADMIN_API_URL } from "../../constants";

interface IResetGraphButtonProps {
  userPhone?: string;
  showNotification: (msg: string, level?: string) => void;
}

const sanitizeRenderProps = (props) => {
  const {userPhone, showNotification, ...sanitized} = props;
  return sanitized
} 

class ResetGraphButton extends Component<IResetGraphButtonProps> {
  handleClick = e => {
    const { userPhone, showNotification } = this.props;
    httpClient(`${ADMIN_API_URL}/users/${userPhone}/graph`, {
        method: "DELETE"
      })
      .then(() => {
        showNotification("Graph reset successful");
      })
      .catch(err => {
        showNotification(err.message, "error");
      });
  };

  render() {
    return (
      <Button color="primary" {...sanitizeRenderProps(this.props)} onClick={this.handleClick}>
        Reset Graph
      </Button>
    );
  }
}

export default connect(
  undefined,
  { showNotification }
)(ResetGraphButton);
