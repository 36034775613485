import { Action } from "redux";
import { delay } from "redux-saga";
import { put, takeEvery, takeLatest, all } from "redux-saga/effects";
import { SET_SIDEBAR_VISIBILITY } from "react-admin";

import { FETCH_ALL_SUCCESS, fetchAll } from "./actions";

interface ServiceBreak {
  service: string;
  beginsAt: Date;
  endsAt: Date;
}

interface FetchAction extends Action {
  payload: {
    data: ServiceBreak[];
  };
}

function* refreshServiceBreaks(waitTime = 300000) {
  yield delay(waitTime);
  yield put(fetchAll());
}

/**
 * Retrieves active service breaks from API on regular intervals
 */
export default function* maintenanceBreakStateSaga() {
  yield all([
    takeLatest(SET_SIDEBAR_VISIBILITY, function* () {
      yield put(fetchAll());
    }),
    takeEvery(FETCH_ALL_SUCCESS, function* () {
      yield refreshServiceBreaks();
    }),
  ]);
}
