import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { submit, isSubmitting } from "redux-form";
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  CREATE,
  withDataProvider,
  crudGetOne,
} from "react-admin";
import IconBlock from "@material-ui/icons/Block";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { IResponse } from "../data";
import { IUser } from "../domain";

interface IProps {
  submit: (string) => void;
  fetchStart: () => void;
  fetchEnd: () => void;
  showNotification: (a: string, b: string) => void;
  crudGetOne: (r: string, id: string, basePath: string, refresh: boolean) => void;
  isSubmitting: boolean;
  dataProvider: (
    t: string,
    resource: string,
    params?: object
  ) => Promise<IResponse>;
  user: IUser;
  resource: string;
  basePath: string;
}

interface IState {
  error: boolean;
  showDialog: boolean;
}

class BlockUserButton extends Component<IProps, IState> {
  state = {
    error: false,
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    submit("block-user");
  };

  handleSubmit = values => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      dataProvider,
      user,
      resource,
      crudGetOne,
      basePath
    } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, "blocked-users", {
      data: Object.assign({}, values, { ssn: user.ssn })
    })
      .then(({ json }) => {
        crudGetOne(resource, user.phone_number, basePath, true);
        this.setState({ showDialog: false });
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting, user } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="pivo.users.block">
          <IconBlock />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Block user"
        >
          <DialogTitle>Block user {user && user.full_name}</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              form="block-user"
              resource="blocked-users"
              // We override the redux-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
            >
              <TextInput source="reason" validate={required()} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
              label="pivo.users.block"
            />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  crudGetOne
};

const enhance = compose(
  withDataProvider,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(BlockUserButton);
