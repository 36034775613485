import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { withTranslate } from "ra-core";

const styles = ({ spacing }) =>
  createStyles({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
    icon: {
      marginRight: spacing.unit,
    },
  });

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = { touched: false, error: "" }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const ChangePasswordForm = ({
  classes,
  error,
  isLoading,
  handleSubmit,
  translate,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className={classes.form}>
      <div className={classes.input}>
        <Field
          autoFocus
          id="password"
          name="password"
          type="password"
          component={renderInput}
          label="New password"
          disabled={isLoading}
        />
      </div>
    </div>
    {error ? (
      <Typography color="error" align="center">
        {error}
      </Typography>
    ) : undefined}
    <CardActions>
      <Button
        variant="raised"
        type="submit"
        color="primary"
        disabled={isLoading}
        className={classes.button}
      >
        {isLoading && (
          <CircularProgress className={classes.icon} size={18} thickness={2} />
        )}
        {translate("ra.auth.sign_in")}
      </Button>
    </CardActions>
  </form>
);

const mapStateToProps = (state) => ({
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  withStyles(styles),
  withTranslate,
  connect(mapStateToProps),
  reduxForm({
    form: "resetPassword",
    validate: (values, props) => {
      const errors = { otp: "" };
      const { translate } = props;
      if (!values.otp) {
        errors.otp = translate("ra.validation.required");
      }
      return errors;
    },
  })
);

const EnhancedChangePasswordForm = enhance(ChangePasswordForm);

EnhancedChangePasswordForm.propTypes = {
  redirectTo: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default EnhancedChangePasswordForm;
