import React from "react";
import classnames from "classnames";

import compose from "recompose/compose";

import { Title, List, Datagrid, TextField } from "react-admin";
import Card from "@material-ui/core/Card";
import { withStyles, createStyles } from "@material-ui/core/styles";

import UserListSearchForm from "./components/UserListSearchForm";

export const styles = createStyles({
  root: {
    display: "flex",
  },
  card: {
    position: "relative",
    flex: "1 1 auto",
    display: "flex",
  },
});

const UserListSearch = (props) => {
  const {
    hasCreate,
    hasShow,
    hasEdit,
    hasList,
    basePath,
    className,
    title,
    classes,
    handleSubmit,
    isLoading,
    translate,
    ...rest
  } = props;
  const { defaultTitle } = rest;
  return (
    <div
      className={classnames("search-page", classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      <Title title={title} defaultTitle={defaultTitle} />
      <Card className={classes.card}>
        <UserListSearchForm />
      </Card>
    </div>
  );
};

const sanitizeRestProps = ({ ...rest }) => rest;

const enhance = compose(withStyles(styles));

export default enhance(UserListSearch);
