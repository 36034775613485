import restClient from "./restClient";

export const convertDataRequestToHTTP = (apiUrl, type, params) => {
  const {userPhone, ...restFilters} = params.filter; 
  return restClient.convertDataRequestToHTTP(
    apiUrl,
    type,
    `users/${userPhone}/payments`,
    {...params, filter: restFilters}
  );
};

export default { convertDataRequestToHTTP };
